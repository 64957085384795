import.meta.glob([
    '../images/**',
    '../fonts/**/*.{ttf,woff,woff2}',
])
import AsyncAlpine from 'async-alpine'
import * as Sentry from "@sentry/browser"

if (window.sentryJavascriptDsn.length > 0) {
    Sentry.init({
        dsn: window.sentryJavascriptDsn,
        environment: window.environment,
        release: window.sentryRelease,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 0.1,
    })
}


import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm'

window.Alpine = Alpine

import CConsent from './modules/CConsent'
import Lightbox from './modules/Lightbox'

import Tooltip from './modules/Tooltip'
import Dialog from '@alpinejs/ui'
import mask from '@alpinejs/mask'

CConsent()
Lightbox()

Alpine.directive('tooltip', Tooltip)
Alpine.plugin(Dialog)
Alpine.plugin(mask)

Alpine.plugin(AsyncAlpine);
Alpine.asyncData('map', () => import('./components/map.js'))
Alpine.asyncData('youtubevideo', () => import('./components/youtubevideo.js'))

Livewire.start()